import React, { Suspense } from "react";
import { Redirect } from "react-router-dom";
import { roles } from "../constants/roles";
import { toastr } from "react-redux-toastr";
import Loader from "../shared/components/Loader";

export const renderComponent = (route, sessionPrivileges, profile, stripe) => (props) => {
  const isAuthenticated = !profile?.isEmpty;
  const needAccess = route.privilege ?? false;
  const hasAccess = sessionPrivileges.includes(route.privilege);

  if (!route.bypass) {
    document.title = route.title
      ? route.title
      : route.name
      ? `${route.name} | Dimension Waste Management`
      : "Waste Removal, Mattress Removal and Dumpster Rentals - Same Day Junk Removal | Dimension Waste Management";
  }

  if (profile?.isBanned && !["/", "/account"].includes(route.path)) {
    toastr.info("You have a ban imposed for your account. Redirecting...");
    return <Redirect to="/account" />;
  }

  if (needAccess && !hasAccess) {
    // toastr.info("You do not have access to this page. Redirecting...");
    return isAuthenticated ? <Redirect to={roles[profile.userType].homepage} /> : <Redirect to="/" />;
  }

  const component = () => (
    <Suspense fallback={<Loader />}>
      <route.component {...props} />
    </Suspense>
  );
  return component();
};
