export const getTitle = () => {
  const posts = {
    ["/provider_portal"]: {
      title: "Providers - Start Growing Your Business Today | Dimension Waste Management",
      description:
        "Dimension makes it simple for anyone with a hauling or dumpster rental business to connect to hundreds of jobs nearby, all with just the click of a button.",
    },
    ["/about_us"]: {
      title: "Fast, Reliable and Sustainable - About Us | Dimension Waste Management",
      description: "Dimension is the first tech-enabled service to radically simplify waste management for companies of any size, nationwide.",
    },
    ["/sustainability"]: {
      title: "Sustainability and Waste Diversion - Waste Removal Services | Dimension Waste Management",
      description:
        "Dimension empowers you with the tools and tech you need to ensure that your waste management is as good for the environment as it is for your business.",
    },
  };
  const path = document.location.pathname;
  if (!posts[path]) return;
  document.title = posts[path]?.title ?? "Waste Removal, Mattress Removal and Dumpster Rentals - Same Day Junk Removal | Dimension Waste Management";
  document.querySelector('meta[name="description"]').content =
    posts[path]?.description ?? "Dimension is the all-in-one solution for waste management, nationwide.";
};
