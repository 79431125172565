export const categories = {
  BOOKING: {
    name: "BOOKING",
    label: "Booking",
  },
  SETTINGS: {
    name: "SETTINGS",
    label: "Settings",
  },
  PORTAL: {
    name: "PORTAL",
    label: "Portal",
  },
  PORTAL_SETTINGS: {
    name: "PORTAL_SETTINGS",
    label: "Portal Settings",
  },
  CUSTOMER: {
    name: "CUSTOMER",
    label: "Customer",
  },
  PROVIDER: {
    name: "PROVIDER",
    label: "Provider",
  },
  BOOKING_FIELD: {
    name: "BOOKING_FIELD",
    label: "Booking Field",
  },
  USER_FIELD: {
    name: "USER_FIELD",
    label: "User Field",
  },
};

const bookingPrivileges = {
  CREATE_BOOKING: {
    name: "CREATE_BOOKING",
    label: "Create Booking",
    category: categories.BOOKING.name,
  },
  DO_IT_AGAIN_BOOKING: {
    name: "DO_IT_AGAIN_BOOKING",
    label: "Do It Again",
    category: categories.BOOKING.name,
  },
  EDIT_BOOKING: {
    name: "EDIT_BOOKING",
    label: "Edit Booking",
    category: categories.BOOKING.name,
  },
  DELETE_BOOKING: {
    name: "DELETE_BOOKING",
    label: "Delete Booking",
    category: categories.BOOKING.name,
  },
  VIEW_CUSTOMER_BOOKING_PAGE: {
    name: "VIEW_CUSTOMER_BOOKING_PAGE",
    label: "View Customer Booking Page",
    category: categories.BOOKING.name,
  },
  VIEW_PROVIDER_BOOKING_PAGE: {
    name: "VIEW_PROVIDER_BOOKING_PAGE",
    label: "View Provider Booking",
    category: categories.BOOKING.name,
  },
  ADD_INVOICE: {
    name: "ADD_INVOICE",
    label: "Add Invoice",
    category: categories.BOOKING.name,
  },
  RECONCILE_BOOKING: {
    name: "RECONCILE_BOOKING",
    label: "Reconcile Booking",
    category: categories.BOOKING.name,
  },
};

const userProfilePrivileges = {
  VIEW_ACCOUNT_PROFILE: {
    name: "VIEW_ACCOUNT_PROFILE",
    label: "View Account Profile",
    category: categories.SETTINGS.name,
  },
  EDIT_ACCOUNT_PROFILE: {
    name: "EDIT_ACCOUNT_PROFILE",
    label: "Edit Account Profile",
    category: categories.SETTINGS.name,
  },
  VIEW_ACCOUNT_SETTINGS: {
    name: "VIEW_ACCOUNT_SETTINGS",
    label: "View Account Settings",
    category: categories.SETTINGS.name,
  },
  EDIT_ACCOUNT_SETTINGS: {
    name: "EDIT_ACCOUNT_SETTINGS",
    label: "Edit Account Settings",
    category: categories.SETTINGS.name,
  },
  VIEW_COMPANY_SETTINGS: {
    name: "VIEW_COMPANY_SETTINGS",
    label: "View Company Settings",
    category: categories.SETTINGS.name,
  },
  EDIT_COMPANY_SETTINGS: {
    name: "EDIT_COMPANY_SETTINGS",
    label: "Edit Company Settings",
    category: categories.SETTINGS.name,
  },
};

const mimicUserPrivileges = {
  SHOW_MIMIC_LOGIN_AS_ENTRY_CUSTOMER: {
    name: "SHOW_MIMIC_LOGIN_AS_ENTRY_CUSTOMER",
    label: "Show Login As Entry (Customer)",
    category: categories.PORTAL_SETTINGS.name,
  },
  SHOW_MIMIC_LOGIN_AS_ENTRY_PROVIDER: {
    name: "SHOW_MIMIC_LOGIN_AS_ENTRY_PROVIDER",
    label: "Show Login As Entry (Provider)",
    category: categories.PORTAL_SETTINGS.name,
  },
  MIMIC_LOGIN_USER_EDIT_PROFILE: {
    name: "MIMIC_LOGIN_USER_EDIT_PROFILE",
    label: "Mimic Login User Edit Profile",
    category: categories.PORTAL_SETTINGS.name,
  },
};

const portalSettingsPrivileges = {
  VIEW_PORTAL_SETTINGS: {
    name: "VIEW_PORTAL_SETTINGS",
    label: "View Portal Settings",
    category: categories.PORTAL_SETTINGS.name,
  },
  EDIT_PORTAL_SETTINGS: {
    name: "EDIT_PORTAL_SETTINGS",
    label: "Edit Portal Settings",
    category: categories.PORTAL_SETTINGS.name,
  },
  VIEW_PORTAL_DOWNLOAD_CSV: {
    name: "VIEW_PORTAL_DOWNLOAD_CSV",
    label: "View Portal Download CSV",
    category: categories.PORTAL_SETTINGS.name,
  },
  VIEW_PORTAL_DATA_EXPORTS: {
    name: "VIEW_PORTAL_DATA_EXPORTS",
    label: "View Portal Data Exports",
    category: categories.PORTAL_SETTINGS.name,
  },
  VIEW_PORTAL_AB_TESTS: {
    name: "VIEW_PORTAL_AB_TESTS",
    label: "View Portal AB Tests",
    category: categories.PORTAL_SETTINGS.name,
  },
  VIEW_PORTAL_NOTIFICATIONS: {
    name: "VIEW_PORTAL_NOTIFICATIONS",
    label: "View Portal Notifications",
    category: categories.PORTAL_SETTINGS.name,
  },
  VIEW_PORTAL_TEAM: {
    name: "VIEW_PORTAL_TEAM",
    label: "View Portal Team",
    category: categories.PORTAL_SETTINGS.name,
  },
  VERIFY_WARRIOR: {
    name: "VERIFY_WARRIOR",
    label: "Verify warrior",
    category: categories.PORTAL_SETTINGS.name,
    disabled: true,
  },
};

const portalBookingPrivileges = {
  SHOW_PORTAL_BOOKINGS_TAB: {
    name: "VIEW_PORTAL_BOOKINGS_TAB",
    label: "View Portal Bookings Tab",
    category: categories.PORTAL.name,
  },
  CREATE_PORTAL_BOOKING: {
    name: "CREATE_PORTAL_BOOKING",
    label: "Create Portal Booking",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_BOOKING: {
    name: "EDIT_PORTAL_BOOKING",
    label: "Edit Portal Booking",
    category: categories.PORTAL.name,
  },
  DELETE_PORTAL_BOOKING: {
    name: "DELETE_PORTAL_BOOKING",
    label: "Delete Portal Booking",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_BOOKING: {
    name: "VIEW_PORTAL_BOOKING",
    label: "View Portal Booking",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_BOOKING_RAW_DATA: {
    name: "VIEW_PORTAL_BOOKING_RAW_DATA",
    label: "View Portal Booking Raw Data",
    category: categories.PORTAL.name,
  },
  FILTER_BY_CLOSED_BY: {
    name: "FILTER_BY_CLOSED_BY",
    label: "Filter Bookings by Closed By",
    category: categories.PORTAL.name,
  },
  EDIT_CLOSED_BY: {
    name: "EDIT_CLOSED_BY",
    label: "Edit the Closed by",
    category: categories.PORTAL.name,
  },
  SHOW_PORTAL_SUBSCRIPTIONS_TAB: {
    name: "SHOW_PORTAL_SUBSCRIPTIONS_TAB",
    label: "Show Portal Subscriptions Tab",
    category: categories.PORTAL.name,
  },
  SHOW_PORTAL_BUNDLES_TAB: {
    name: "SHOW_PORTAL_BUNDLES_TAB",
    label: "Show Portal Bundles Tab",
    category: categories.PORTAL.name,
  },
  SHOW_INVOICE_EMAIL_CHECK: {
    name: "SHOW_INVOICE_EMAIL_CHECK",
    label: "Show Invoice Email Check",
    category: categories.PORTAL.name,
  },
};

const portalCustomerPrivileges = {
  SHOW_PORTAL_CUSTOMERS_TAB: {
    name: "VIEW_PORTAL_CUSTOMERS_TAB",
    label: "View Portal Customers Tab",
    category: categories.PORTAL.name,
  },
  SHOW_PORTAL_GUEST_CUSTOMERS_TAB: {
    name: "VIEW_PORTAL_GUEST_CUSTOMERS_TAB",
    label: "View Portal Guest Customers Tab",
    category: categories.PORTAL.name,
  },
  SHOW_PORTAL_BUSINESS_ACCOUNTS_TAB: {
    name: "VIEW_PORTAL_BUSINESS_ACCOUNTS_TAB",
    label: "View Portal Business Accounts Tab",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_CUSTOMER: {
    name: "EDIT_PORTAL_CUSTOMER",
    label: "Edit Portal Customer",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_CUSTOMER: {
    name: "VIEW_PORTAL_CUSTOMER",
    label: "View Portal Customer",
    category: categories.PORTAL.name,
  },
};

const portalProviderPrivileges = {
  SHOW_PORTAL_PROVIDERS_TAB: {
    name: "VIEW_PORTAL_PROVIDERS_TAB",
    label: "View Portal Providers Tab",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_PROVIDER: {
    name: "EDIT_PORTAL_PROVIDER",
    label: "Edit Portal Provider",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_PROVIDER: {
    name: "VIEW_PORTAL_PROVIDER",
    label: "View Portal Provider",
    category: categories.PORTAL.name,
  },
  EDIT_PROVIDER_TIER: {
    name: "EDIT_PROVIDER_TIER",
    label: "Edit Provider Tier",
    category: categories.PORTAL.name,
  },
};

const portalRecurringBookingPrivileges = {
  SHOW_PORTAL_RECURRING_BOOKINGS_TAB: {
    name: "VIEW_PORTAL_RECURRING_BOOKINGS_TAB",
    label: "View Portal Recurring Bookings Tab",
    category: categories.PORTAL.name,
  },
  CREATE_PORTAL_RECURRING_BOOKING: {
    name: "CREATE_PORTAL_RECURRING_BOOKING",
    label: "Create Portal Recurring Booking",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_RECURRING_BOOKING: {
    name: "EDIT_PORTAL_RECURRING_BOOKING",
    label: "Edit Portal Recurring Booking",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_RECURRING_BOOKING: {
    name: "VIEW_PORTAL_RECURRING_BOOKING",
    label: "View Portal Recurring Booking",
    category: categories.PORTAL.name,
  },
  RECONCILE_RECURRING_BOOKINGS: {
    name: "RECONCILE_RECURRING_BOOKINGS",
    label: "Reconcile Recurring Booking Instances",
    category: categories.PORTAL.name,
  },
  EDIT_RECURRING_BOOKING_UPSELL: {
    name: "EDIT_RECURRING_BOOKING_UPSELL",
    label: "Edit Recurring Booking Upsell",
    category: categories.PORTAL.name,
    fineGrained: true,
  },
};

const portalProviderQuotesPrivileges = {
  SHOW_PORTAL_PROVIDER_QUOTES_TAB: {
    name: "VIEW_PORTAL_PROVIDER_QUOTES_TAB",
    label: "View Portal Provider Quotes Tab",
    category: categories.PORTAL.name,
  },
  CREATE_PORTAL_PROVIDER_QUOTE: {
    name: "CREATE_PORTAL_PROVIDER_QUOTE",
    label: "Create Portal Provider Quote",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_PROVIDER_QUOTE: {
    name: "EDIT_PORTAL_PROVIDER_QUOTE",
    label: "Edit Portal Provider Quote",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_PROVIDER_QUOTE: {
    name: "VIEW_PORTAL_PROVIDER_QUOTE",
    label: "View Portal Provider Quote",
    category: categories.PORTAL.name,
  },
};

const portalCouponPrivileges = {
  SHOW_PORTAL_COUPONS_TAB: {
    name: "VIEW_PORTAL_COUPONS_TAB",
    label: "View Portal Coupons Tab",
    category: categories.PORTAL.name,
  },
  CREATE_PORTAL_COUPON: {
    name: "CREATE_PORTAL_COUPON",
    label: "Create Portal Coupon",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_COUPON: {
    name: "EDIT_PORTAL_COUPON",
    label: "Edit Portal Coupon",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_COUPON: {
    name: "VIEW_PORTAL_COUPON",
    label: "View Portal Coupon",
    category: categories.PORTAL.name,
  },
};

const portalVipCustomerPrivileges = {
  SHOW_PORTAL_VIP_CUSTOMERS_TAB: {
    name: "VIEW_PORTAL_VIP_CUSTOMERS_TAB",
    label: "View Portal VIP Customers Tab",
    category: categories.PORTAL.name,
  },
  CREATE_PORTAL_VIP_CUSTOMER: {
    name: "CREATE_PORTAL_VIP_CUSTOMER",
    label: "Create Portal VIP Customer",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_VIP_CUSTOMER: {
    name: "EDIT_PORTAL_VIP_CUSTOMER",
    label: "Edit Portal VIP Customer",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_VIP_CUSTOMER: {
    name: "VIEW_PORTAL_VIP_CUSTOMER",
    label: "View Portal VIP Customer",
    category: categories.PORTAL.name,
  },
};

const portalInvoicesPrivileges = {
  SHOW_PORTAL_INVOICES_TAB: {
    name: "VIEW_PORTAL_INVOICES_TAB",
    label: "View Portal Invoices Tab",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_INVOICE: {
    name: "EDIT_PORTAL_INVOICE",
    label: "Edit Portal Invoice",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_INVOICE: {
    name: "VIEW_PORTAL_INVOICE",
    label: "View Portal Invoice",
    category: categories.PORTAL.name,
  },
  CREATE_COMBINED_INVOICE: {
    name: "CREATE_COMBINED_INVOICE",
    label: "Create Combined Invoice",
    category: categories.PORTAL.name,
  },
  CREATE_GENERIC_INVOICE: {
    name: "CREATE_GENERIC_INVOICE",
    label: "Create Generic Invoice",
    category: categories.PORTAL.name,
  },
};

const portalDumpsitesPrivileges = {
  SHOW_PORTAL_DUMP_SITES_TAB: {
    name: "VIEW_PORTAL_DUMP_SITES_TAB",
    label: "View Portal Dump Sites Tab",
    category: categories.PORTAL.name,
  },
  CREATE_PORTAL_DUMP_SITE: {
    name: "CREATE_PORTAL_DUMP_SITE",
    label: "Create Portal Dump Site",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_DUMP_SITE: {
    name: "EDIT_PORTAL_DUMP_SITE",
    label: "Edit Portal Dump Site",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_DUMP_SITE: {
    name: "VIEW_PORTAL_DUMP_SITE",
    label: "View Portal Dump Site",
    category: categories.PORTAL.name,
  },
};
const portalImpactPriviledges = {
  SHOW_PORTAL_IMPACT_TAB: {
    name: "VIEW_PORTAL_IMPACT_TAB",
    label: "View Portal Impact Tab",
    category: categories.PORTAL.name,
  },
};

const portalCustomerQuotesPrivileges = {
  SHOW_PORTAL_CUSTOMER_QUOTES_TAB: {
    name: "VIEW_PORTAL_CUSTOMER_QUOTES_TAB",
    label: "View Portal Customer Quotes Tab",
    category: categories.PORTAL.name,
  },
  CREATE_PORTAL_CUSTOMER_QUOTE: {
    name: "CREATE_PORTAL_CUSTOMER_QUOTE",
    label: "Create Portal Customer Quote",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_CUSTOMER_QUOTE: {
    name: "EDIT_PORTAL_CUSTOMER_QUOTE",
    label: "Edit Portal Customer Quote",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_CUSTOMER_QUOTE: {
    name: "VIEW_PORTAL_CUSTOMER_QUOTE",
    label: "View Portal Customer Quote",
    category: categories.PORTAL.name,
  },
};

const portalBusinessAccountPrivileges = {
  SHOW_PORTAL_BUSINESS_ACCOUNT_TAB: {
    name: "SHOW_PORTAL_BUSINESS_ACCOUNT_TAB",
    label: "View Portal Business Account Tab",
    category: categories.PORTAL.name,
  },
  CREATE_PORTAL_BUSINESS_ACCOUNT: {
    name: "CREATE_PORTAL_BUSINESS_ACCOUNT",
    label: "Create Portal Business Account",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_BUSINESS_ACCOUNT: {
    name: "EDIT_PORTAL_BUSINESS_ACCOUNT",
    label: "Edit Portal Business Account",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_BUSINESS_ACCOUNT: {
    name: "VIEW_PORTAL_BUSINESS_ACCOUNT",
    label: "View Portal Business Account",
    category: categories.PORTAL.name,
  },
};

const portalServiceLocationPrivileges = {
  SHOW_PORTAL_SERVICE_LOCATIONS_TAB: {
    name: "VIEW_PORTAL_SERVICE_LOCATIONS_TAB",
    label: "View Portal Service Locations Tab",
    category: categories.PORTAL.name,
  },
  CREATE_PORTAL_SERVICE_LOCATION: {
    name: "CREATE_PORTAL_SERVICE_LOCATION",
    label: "Create Portal Service Location",
    category: categories.PORTAL.name,
  },
  EDIT_PORTAL_SERVICE_LOCATION: {
    name: "EDIT_PORTAL_SERVICE_LOCATION",
    label: "Edit Portal Service Location",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_SERVICE_LOCATION: {
    name: "VIEW_PORTAL_SERVICE_LOCATION",
    label: "View Portal Service Location",
    category: categories.PORTAL.name,
  },
};

const portalSalesPriviledges = {
  SHOW_PORTAL_SALES_REPS_TAB: {
    name: "SHOW_PORTAL_SALES_REPS_TAB",
    label: "View Portal Sales Reps Tab",
    categories: categories.PORTAL.name,
  },
  SHOW_PORTAL_SALES_BUSINESSES_TAB: {
    name: "SHOW_PORTAL_SALES_BUSINESSES_TAB",
    label: "View Portal Sales Businesses Tab",
    categories: categories.PORTAL.name,
  },
};

const portalSalesDashBoardPrivileges = {
  SHOW_PORTAL_SALES_DASHBOARD_TAB: {
    name: "VIEW_PORTAL_SALES_DASHBOARD_TAB",
    label: "View Portal Sales Dashboard Tab",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_SALES_DASHBOARD: {
    name: "VIEW_PORTAL_SALES_DASHBOARD",
    label: "View Portal Sales Dashboard",
    category: categories.PORTAL.name,
  },
  SHOW_PORTAL_SALES_COMMISSIONS: {
    name: "SHOW_PORTAL_SALES_COMMISSIONS",
    label: "Show Portal Sales Commissions Tab",
    category: categories.PORTAL.name,
  },
  VIEW_PORTAL_SALES_COMMISSIONS_ALL: {
    name: "VIEW_PORTAL_SALES_COMMISSIONS_ALL",
    label: "View All Sales Commision",
    categories: categories.PORTAL.name,
  },
  SET_SALES_GOALS: {
    name: "SET_SALES_GOALS",
    label: "Set Sales Goals",
    categories: categories.PORTAL.name,
  },
  SHOW_RETENTION_SHEET: {
    name: "SHOW_RETENTION_SHEET",
    label: "Show Retention Sheet",
    categories: categories.PORTAL.name,
  },
  EXPORT_SALES_DASHBOARD: {
    name: "EXPORT_SALES_DASHBOARD",
    label: "Export Sales Dash",
    categories: categories.PORTAL.name,
  },
};

const customerPrivileges = {
  VIEW_MY_BOOKINGS_PAGE: {
    name: "VIEW_MY_BOOKINGS_PAGE",
    label: "View My Bookings Page",
    category: categories.CUSTOMER.name,
  },
  VIEW_INTEGRATIONS_PAGE: {
    name: "VIEW_INTEGRATIONS_PAGE",
    label: "View Integrations Page",
    category: categories.CUSTOMER.name,
  },
};

const providerPrivileges = {
  VIEW_GET_TASKS_PAGE: {
    name: "VIEW_GET_TASKS_PAGE",
    label: "View Get Tasks Page",
    category: categories.PROVIDER.name,
  },
};

export const portalBookingFieldPrivileges = {
  EDIT_BOOKING_TASK_FIELD: {
    name: "EDIT_BOOKING_TASK_FIELD",
    label: "Edit Booking Task Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_FREQUENCY_FIELD: {
    name: "EDIT_BOOKING_FREQUENCY_FIELD",
    label: "Edit Booking Frequency Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_DESCRIPTION_FIELD: {
    name: "EDIT_BOOKING_DESCRIPTION_FIELD",
    label: "Edit Booking Description Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_STAIRS_FIELD: {
    name: "EDIT_BOOKING_STAIRS_FIELD",
    label: "Edit Booking Stairs Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_DISMANTLING_FIELD: {
    name: "EDIT_BOOKING_DISMANTLING_FIELD",
    label: "Edit Booking Dismantling Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_DATE_FIELD: {
    name: "EDIT_BOOKING_DATE_FIELD",
    label: "Edit Booking Date Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_TIME_FIELD: {
    name: "EDIT_BOOKING_TIME_FIELD",
    label: "Edit Booking Time Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_PICKUP_TYPE_FIELD: {
    name: "EDIT_BOOKING_PICKUP_TYPE_FIELD",
    label: "Edit Booking Pickup Type Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_VEHICLE_FIELD: {
    name: "EDIT_BOOKING_VEHICLE_FIELD",
    label: "Edit Booking Vehicle Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_CUSTOMER_FIELD: {
    name: "EDIT_BOOKING_CUSTOMER_FIELD",
    label: "Edit Booking Customer Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_COMPANY_FIELD: {
    name: "EDIT_BOOKING_COMPANY_FIELD",
    label: "Edit Booking Company Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_CLOSED_BY_FIELD: {
    name: "EDIT_BOOKING_CLOSED_BY_FIELD",
    label: "Edit Booking Closed By Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_PROVIDER_FIELD: {
    name: "EDIT_BOOKING_PROVIDER_FIELD",
    label: "Edit Booking Provider Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_SERVICE_LOCATION_FIELD: {
    name: "EDIT_BOOKING_SERVICE_LOCATION_FIELD",
    label: "Edit Booking Service Location Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_ADDITIONAL_ADDRESS_FIELD: {
    name: "EDIT_BOOKING_ADDITIONAL_ADDRESS_FIELD",
    label: "Edit Booking Additional Address Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_DROP_OFF_FIELD: {
    name: "EDIT_BOOKING_DROP_OFF_FIELD",
    label: "Edit Booking Drop Off Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_PRICING_ITEMS_FIELD: {
    name: "EDIT_BOOKING_PRICING_ITEMS_FIELD",
    label: "Edit Booking Pricing Items Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_CUSTOMER_PRICING_FIELD: {
    name: "EDIT_BOOKING_CUSTOMER_PRICING_FIELD",
    label: "Edit Booking Customer Pricing Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_PROVIDER_PRICING_FIELD: {
    name: "EDIT_BOOKING_PROVIDER_PRICING_FIELD",
    label: "Edit Booking Provider Pricing Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_PAYMENT_METHOD_FIELD: {
    name: "EDIT_BOOKING_PAYMENT_METHOD_FIELD",
    label: "Edit Booking Payment Method Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_GCLID_FIELD: {
    name: "EDIT_BOOKING_GCLID_FIELD",
    label: "Edit Booking GCLID Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_GCLID_INTERCOMM_FIELD: {
    name: "EDIT_BOOKING_GCLID_INTERCOMM_FIELD",
    label: "Edit Booking GCLID Intercomm Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_INVOICE_FIELD: {
    name: "EDIT_BOOKING_INVOICE_FIELD",
    label: "Edit Booking Invoice Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_INVOICE_NOTES_FIELD: {
    name: "EDIT_BOOKING_INVOICE_NOTES_FIELD",
    label: "Edit Booking Invoice Notes Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_STATUS_FIELD_LIMITED: {
    name: "EDIT_BOOKING_STATUS_FIELD_LIMITED",
    label: "Edit Booking Status Field Limited",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_BID_STATUS_FIELD: {
    name: "EDIT_BOOKING_BID_STATUS_FIELD",
    label: "Edit Booking Status Field",
    category: categories.BOOKING_FIELD.name,
  },
  EDIT_BOOKING_TASK_IMAGE_FIELD: {
    name: "EDIT_BOOKING_TASK_IMAGE_FIELD",
    label: "Edit Booking Task Image Field",
    category: categories.BOOKING_FIELD.name,
  },
};

export const portalUserFieldPrivileges = {
  EDIT_USER_AVATAR_FIELD: {
    name: "EDIT_USER_AVATAR_FIELD",
    label: "Edit User Avatar Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_USER_FIRST_NAME_FIELD: {
    name: "EDIT_USER_FIRST_NAME_FIELD",
    label: "Edit User First Name Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_USER_LAST_NAME_FIELD: {
    name: "EDIT_USER_LAST_NAME_FIELD",
    label: "Edit User Last Name Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_USER_EMAIL_FIELD: {
    name: "EDIT_USER_EMAIL_FIELD",
    label: "Edit User Email Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_USER_PHONE_FIELD: {
    name: "EDIT_USER_PHONE_FIELD",
    label: "Edit User Phone Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_USER_PASSWORD_FIELD: {
    name: "EDIT_USER_PASSWORD_FIELD",
    label: "Edit User Password Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_USER_BUSINESS_FIELD: {
    name: "EDIT_USER_BUSINESS_FIELD",
    label: "Edit User Business Field",
    category: categories.USER_FIELD.name,
    fineGrained: true,
  },
  EDIT_GUEST_USER_BUSINESS_FIELD: {
    name: "EDIT_GUEST_USER_BUSINESS_FIELD",
    label: "Edit Guest User Business Field",
    category: categories.USER_FIELD.name,
    fineGrained: true,
  },
  EDIT_COMISSION_OVERRIDE_FIELD: {
    name: "EDIT_COMISSION_OVERRIDE_FIELD",
    label: "Edit Comission Override Field",
    category: categories.USER_FIELD.name,
    fineGrained: true,
  },
  EDIT_USER_STRIPE_FIELD: {
    name: "EDIT_USER_STRIPE_FIELD",
    label: "Edit User Stripe Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_USER_PAYMENT_METHOD_FIELD: {
    name: "EDIT_USER_PAYMENT_METHOD_FIELD",
    label: "Edit User Payment Method Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_USER_NOTES_FIELD: {
    name: "EDIT_USER_NOTES_FIELD",
    label: "Edit User Notes Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_BG_CLEARANCE_FIELD: {
    name: "EDIT_BG_CLEARANCE_FIELD",
    label: "Edit BG Clearance Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_ACCOUNT_MANAGER_FIELD: {
    name: "EDIT_ACCOUNT_MANAGER_FIELD",
    label: "Edit Account Manager Field",
    category: categories.USER_FIELD.name,
  },
  EDIT_USER_BILLING_EMAIL_FIELD: {
    name: "EDIT_USER_BILLING_EMAIL_FIELD",
    label: "Edit User Billing Email Field",
    category: categories.USER_FIELD.name,
  },
};

export const alertingPrivileges = {
  SEND_BOOKING_UPDATE_ALERT: {
    name: "SEND_BOOKING_UPDATE_ALERT",
    label: "Send Booking Update Alert",
    category: categories.BOOKING.name,
  },
};

export const sourcingPriviledges = {
  VIEW_SOURCING: {
    name: "VIEW_SOURCING",
    label: "View Sourcing",
    category: categories.SETTINGS,
  },
  EDIT_SOURCING: {
    name: "EDIT_SOURCING",
    label: "Edit Sourcing",
    category: categories.SETTINGS,
  },
};

export const attributePrivileges = {
  VIEW_ATTRIBUTES: {
    name: "VIEW_ATTRIBUTES",
    label: "View Attributes",
    category: categories.SETTINGS,
  },
  EDIT_ATTRIBUTES: {
    name: "EDIT_ATTRIBUTES",
    label: "Edit Attributes",
    category: categories.SETTINGS,
  },
};

export const importerPriviledges = {
  VIEW_IMPORTER: {
    name: "VIEW_IMPORTER",
    label: "View Importer",
    category: categories.SETTINGS,
  },
};

export const paymentDataPriviledges = {
  VIEW_PAYMENT_DATA: {
    name: "VIEW_PAYMENT_DATA",
    label: "View Payment Data Page",
    category: categories.SETTINGS,
  },
};

export const experimentalPriviledges = {
  VIEW_OPENAI_CART: {
    name: "VIEW_OPENAI_CART",
    label: "Open AI Tester",
    categories: categories.SETTINGS,
  },
  VIEW_REPORTS: {
    name: "VIEW_REPORTS",
    label: "View Reports",
    categories: categories.CUSTOMER,
  },
  VIEW_API_TESTER: {
    name: "VIEW_API_TESTER",
    label: "View API Tester",
    categories: categories.SETTINGS,
  },
  VIEW_SCRAPPY_TESTER: {
    name: "VIEW_SCRAPPY_TESTER",
    label: "View Scrappy Tester",
    categories: categories.SETTINGS,
  },
  UPDATE_KEYWORDS: {
    name: "UPDATE_KEYWORDS",
    label: "Update Keywords",
    categories: categories.SETTINGS,
  },
};

export const bookingOverridePriviledges = {
  OVERRIDE_EXISTING_FIELD: {
    name: "OVERRIDE_EXISTING_FIELD",
    label: "Overrides the existing or new logic for bookings",
    category: categories.BOOKING,
  },
};

export const privileges = {
  ...bookingPrivileges,
  ...userProfilePrivileges,
  ...mimicUserPrivileges,
  ...portalSettingsPrivileges,
  ...portalBookingPrivileges,
  ...portalCustomerPrivileges,
  ...portalProviderPrivileges,
  ...portalRecurringBookingPrivileges,
  ...portalProviderQuotesPrivileges,
  ...portalCouponPrivileges,
  ...portalVipCustomerPrivileges,
  ...portalInvoicesPrivileges,
  ...portalDumpsitesPrivileges,
  ...portalCustomerQuotesPrivileges,
  ...portalBusinessAccountPrivileges,
  ...portalServiceLocationPrivileges,
  ...portalSalesDashBoardPrivileges,
  ...customerPrivileges,
  ...providerPrivileges,
  ...portalSalesPriviledges,
  ...portalImpactPriviledges,
  ...portalBookingFieldPrivileges,
  ...portalUserFieldPrivileges,
  ...alertingPrivileges,
  ...attributePrivileges,
  ...sourcingPriviledges,
  ...importerPriviledges,
  ...experimentalPriviledges,
  ...paymentDataPriviledges,
  ...bookingOverridePriviledges,
  // portal entry
  PORTAL_ACCESS: {
    name: "PORTAL_ACCESS",
    label: "Portal Access",
    category: categories.PORTAL.name,
  },
  GUEST_PORTAL_ACCESS: {
    name: "GUEST_PORTAL_ACCESS",
    label: "Guest Portal Access",
    category: categories.PORTAL.name,
  },
  EDIT_BOOKING_STATUS_FIELD: {
    name: "EDIT_BOOKING_STATUS_FIELD",
    label: "Edit Booking Status Field",
    category: categories.BOOKING_FIELD.name,
  },
  // commission
  EDIT_COMMISSION_DISPENSED: {
    name: "EDIT_COMMISSION_DISPENSED",
    label: "Edit Commission Dispensed",
    category: categories.COMMISSION,
    fineGrained: true,
  },
  // template submission creation
  CREATE_TEMPLATE_SUBMISSION: {
    name: "CREATE_TEMPLATE_SUBMISSION",
    label: "Create Template Submission",
    category: categories.BOOKING,
    fineGrained: true,
  },
  // override task expiry
  OVERRIDE_TASK_SUBMISSION_EXPIRY: {
    name: "OVERRIDE_TASK_SUBMISSION_EXPIRY",
    label: "Override Task Submission Expiry",
    category: categories.BOOKING,
    fineGrained: true,
  },
};

export const categoryArray = Object.values(categories).map((category) => category.name);

export const privilegesByCategory = Object.values(privileges).reduce((obj, privilege) => {
  obj[privilege.category] = obj[privilege.category] ? [...obj[privilege.category], { name: privilege.name, label: privilege.label }] : [privilege];
  return obj;
}, []);

// all privs without fine grained
export const allPrivileges = Object.values(privileges).filter((privilege) => !privilege.fineGrained);
