export const FORM_TYPE = {
  ALCOHOL: "alcohol-disposal",
  BEVERAGE: "beverage-disposal",
  COMPOST: "compost-disposal",
  HAZARDOUS_WASTE: "hazardous-waste-disposal",
  JUNK: "junk-disposal",
  PALLET: "pallet-disposal",
  SANITIZER: "sanitizer-disposal",
  SOLAR_PANEL: "solar-panel-disposal",
  SOLAR_SELL: "solar-panel-sell",
  UPDATER: "updater-disposal",
};

export const RECURRING_FORMS = [
  FORM_TYPE.ALCOHOL,
  FORM_TYPE.BEVERAGE,
  FORM_TYPE.COMPOST,
  FORM_TYPE.HAZARDOUS_WASTE,
  FORM_TYPE.JUNK,
  FORM_TYPE.PALLET,
  FORM_TYPE.SOLAR_PANEL,
  FORM_TYPE.SOLAR_SELL,
  FORM_TYPE.UPDATER,
];

export const DYNAMIC_FORMS = [
  FORM_TYPE.ALCOHOL,
  FORM_TYPE.BEVERAGE,
  FORM_TYPE.COMPOST,
  FORM_TYPE.HAZARDOUS_WASTE,
  FORM_TYPE.JUNK,
  FORM_TYPE.SOLAR_PANEL,
  FORM_TYPE.SOLAR_SELL,
  FORM_TYPE.UPDATER,
];

export enum FLOW_VARIANT {
  V1 = "v1", // regular flow with item pickers when applicable, deprecated
  V2 = "v2", // deprecated
  V3 = "v3", // deprecated
  V4 = "v4", // deprecated
  V6 = "v6", // Scrappy 1.0 with Contact info first, deprecated
  V7 = "v7", // Scrappy 1.0 with description first, deprecated
  V8 = "v8", // Scrappy 2.0 with description first
  V9 = "v9", // Scrappy 2.0 with contact info first
  V10 = "v10", // Scrappy 2.0 with high threshold
  V11 = "v11", // Mirror mirror kicks in
  // Add new variants here..
}

export const FLOW_STEPS = {
  CustomerCategory: "CustomerCategory",
  InitialQuote: "InitialQuote",
  CustomerInfo: "CustomerInfo",
  AdditionalInfo: "AdditionalInfo",
  ProductInfoForm: "ProductInfoForm",
  Scrappy: "Scrappy",
  ScrappyV2: "ScrappyV2",
  BookingDetailsForm: "BookingDetailsForm",
  OrderSummary: "OrderSummary",
  Checkout: "Checkout",
  ThankYouMessage: "ThankYouMessage",
  ThankYouQuote: "ThankYouQuote",
  ThankYouCalendly: "ThankYouCalendly",
};

/* These display on order summary */
export const SELF_SERVE_CONTACT_EMAILS = {
  [FORM_TYPE.ALCOHOL]: "jiatao@getdimension.com",
  [FORM_TYPE.BEVERAGE]: "jiatao@getdimension.com",
  [FORM_TYPE.COMPOST]: "jiatao@getdimension.com",
  [FORM_TYPE.HAZARDOUS_WASTE]: "jiatao@getdimension.com",
  [FORM_TYPE.JUNK]: "jiatao@getdimension.com",
  [FORM_TYPE.PALLET]: "jiatao@getdimension.com",
  [FORM_TYPE.SANITIZER]: "jiatao@getdimension.com",
  [FORM_TYPE.SOLAR_PANEL]: "jiatao@getdimension.com",
  [FORM_TYPE.SOLAR_SELL]: "jiatao@getdimension.com",
  [FORM_TYPE.UPDATER]: "jiatao@getdimension.com",
};

/* This determines min amount of days for selfserve */
export const SELF_SERVE_MIN_DAYS = {
  [FORM_TYPE.PALLET]: 4,
  [FORM_TYPE.SANITIZER]: 4,
  [FORM_TYPE.HAZARDOUS_WASTE]: 5,
  [FORM_TYPE.JUNK]: 3,
};

export const AUTO_LIMIT: { [form: string]: number } = {
  [FORM_TYPE.PALLET]: 10000000,
  [FORM_TYPE.SANITIZER]: 4,
  [FORM_TYPE.SOLAR_PANEL]: 10000000,
};

export const keywordHeadingsAndPitches: any = {
  "sanitizer+recycle": {
    heading: "Sanitizer Recycling Solutions",
    material: "Sanitizers & PPE",
    subHeading: "Offering recycling solutions for unused or expired sanitizers and PPE. Help contribute to sustainability by opting for our sanitizer recycling services, suitable for businesses and institutions of all sizes.",
    costExplanation: "The cost of hand sanitizer removal may seem high due to the need for proper handling and disposal of potentially flammable and alcohol-based liquids. Safety measures, compliance with regulations, and environmental considerations contribute to the cost.",
    placeholder: "2x pallets of 16.9fl oz sanitizer bottles\n5x cases of excess hand sanitizer and alcohol wipes\n\nExample details:\nExpired last year\nUnopened",
  },
  "sanitizer+california": {
    heading: "California's Leading Sanitizer Disposal Service",
    material: "Hazardous Waste",
    subHeading: "Dispose of expired hand sanitizer and PPE with our California trusted, reliable and affordable services.",
    costExplanation: "The cost of hand sanitizer removal may seem high due to the need for proper handling and disposal of potentially flammable and alcohol-based liquids. Safety measures, compliance with regulations, and environmental considerations contribute to the cost.",
    placeholder: "2x pallets of 16.9fl oz sanitizer bottles\n5x cases of excess hand sanitizer and alcohol wipes\n\nExample details:\nExpired last year\nUnopened",
  },
  "sanitizer+removal": {
    heading: "Hand Sanitizer and PPE removal services",
    material: "Hazardous Waste",
    subHeading: "We specialize in disposing and recycling hand sanitizer and PPE. Our services are convenient and affordable, with prices starting at $190 per pallet.",
    costExplanation: "The cost of hand sanitizer removal may seem high due to the need for proper handling and disposal of potentially flammable and alcohol-based liquids. Safety measures, compliance with regulations, and environmental considerations contribute to the cost.",
    placeholder: "2x pallets of 16.9fl oz sanitizer bottles\n5x cases of excess hand sanitizer and alcohol wipes\n\nExample details:\nExpired last year\nUnopened",
  },
  "expired+hand+sanitizer": {
    heading: "Expired Hand Sanitizer Disposal",
    material: "Hazardous Waste",
    subHeading: "Dispose of expired hand sanitizer and PPE with our convenient and affordable services.",
    costExplanation: "The cost of hand sanitizer removal may seem high due to the need for proper handling and disposal of potentially flammable and alcohol-based liquids. Safety measures, compliance with regulations, and environmental considerations contribute to the cost.",
    placeholder: "2x pallets of 16.9fl oz sanitizer bottles\n5x cases of excess hand sanitizer and alcohol wipes\n\nExample details:\nExpired last year\nUnopened",
  },
  "expired+sanitizer": {
    heading: "Expired Sanitizer Disposal",
    material: "Hazardous Waste",
    subHeading: "Dispose of expired hand sanitizer and PPE with our convenient and affordable services.",
    costExplanation: "The cost of hand sanitizer removal may seem high due to the need for proper handling and disposal of potentially flammable and alcohol-based liquids. Safety measures, compliance with regulations, and environmental considerations contribute to the cost.",
    placeholder: "2x pallets of 16.9fl oz sanitizer bottles\n5x cases of excess hand sanitizer and alcohol wipes\n\nExample details:\nExpired last year\nUnopened",
  },
  "sanitizer": {
    heading: "Hand Sanitizer and PPE disposal",
    material: "Hazardous Waste",
    subHeading: "From a box of expired hand sanitizer bottles to 1000 pallets of various PPE, we provide the most affordable disposal rates in the U.S. with prices starting at $190 per pallet. We specialize in transporting these products to global factories for ethanol recycling and also donating them.",
    costExplanation: "The cost of hand sanitizer removal may seem high due to the need for proper handling and disposal of potentially flammable and alcohol-based liquids. Safety measures, compliance with regulations, and environmental considerations contribute to the cost.",
    placeholder: "2x pallets of 16.9fl oz sanitizer bottles\n5x cases of excess hand sanitizer and alcohol wipes\n\nExample details:\nExpired last year\nUnopened",
  },
  "oil+recycling": {
    heading: "Eco-Friendly Oil Recycling and Disposal Services",
    material: "Oil",
    subHeading: "We specialize in disposing and recycling used oil and provide pumping and container removal services for all sizes of oil containers. Nationwide flat fee for pumping regardless of volume.",
    costExplanation: "The cost of oil disposal may seem high due to the specialized equipment and processes required to safely handle and recycle used oil. Additionally, strict environmental regulations contribute to the expenses involved in its disposal.",
    placeholder: "1x 250-gallon tote of mechanical oil (pump)\n5x 55-gallon drum of used motor oil\n20x 15-gallon containers of oil",
  },
  "used+oil+pickup": {
    heading: "Affordable Used Oil Pickup Service",
    material: "Oil",
    subHeading: "We specialize in disposing and recycling used oil. We can remove containers of all sizes or pump oil from them if you want to keep the containers on site.",
    costExplanation: "The cost of oil disposal may seem high due to the specialized equipment and processes required to safely handle and recycle used oil. Additionally, strict environmental regulations contribute to the expenses involved in its disposal.",
    placeholder: "1x 250-gallon tote of mechanical oil (pump)\n5x 55-gallon drum of used motor oil\n20x 15-gallon containers of oil",
  },
  "oil+pickup": {
    heading: "Affordable Oil Pickup and Disposal Solutions",
    material: "Oil",
    subHeading: "We specialize in disposing and recycling used oil and provide pumping and container removal services for all sizes of oil containers. Nationwide flat fee for pumping regardless of volume.",
    costExplanation: "The cost of oil disposal may seem high due to the specialized equipment and processes required to safely handle and recycle used oil. Additionally, strict environmental regulations contribute to the expenses involved in its disposal.",
    placeholder: "1x 250-gallon tote of mechanical oil (pump)\n5x 55-gallon drum of used motor oil\n20x 15-gallon containers of oil",
  },
  "used-oil": {
    heading: "Eco-Friendly Used Oil Disposal Solutions",
    material: "Oil",
    subHeading: "We specialize in disposing and recycling used oil and provide pumping and container removal services for all sizes of oil containers.",
    costExplanation: "The cost of oil disposal may seem high due to the specialized equipment and processes required to safely handle and recycle used oil. Additionally, strict environmental regulations contribute to the expenses involved in its disposal.",
    placeholder: "1x 250-gallon tote of mechanical oil (pump)\n5x 55-gallon drum of used motor oil\n20x 15-gallon containers of oil",
  },
  "oil": {
    heading: "Eco-Friendly Oil Recycling and Disposal Solutions",
    material: "Oil",
    subHeading: "We specialize in disposing and recycling oil and provide pumping and container removal services for all sizes of oil containers.",
    costExplanation: "The cost of oil disposal may seem high due to the specialized equipment and processes required to safely handle and recycle used oil. Additionally, strict environmental regulations contribute to the expenses involved in its disposal.",
    placeholder: "1x 250-gallon tote of mechanical oil (pump)\n5x 55-gallon drum of used motor oil\n20x 15-gallon containers of oil",
  },
  "paint+recycle": {
    heading: "Convenient Paint Recycling Services",
    material: "Paint",
    subHeading: "Don't let old paint harm the environment. Our paint recycling services turn your unwanted paint into new products, offering a green solution to paint disposal.",
    costExplanation: "Paint disposal costs can appear significant because of the need for proper handling, storage, and recycling of potentially hazardous materials. Meeting environmental standards and safety measures adds to the overall expense.",
    placeholder: "1x 10-gallon container of paint\n5x 5-gallon buckets of paint\n20x 1-gallon cans of paint",
  },
  "paint": {
    heading: "Paint Disposal, Recycling and Donation Services",
    material: "Paint",
    subHeading: "Whether it's a single gallon or dozens of 10-gallon containers of paint, we have the right disposal and recycling solutions for you.",
    costExplanation: "Paint disposal costs can appear significant because of the need for proper handling, storage, and recycling of potentially hazardous materials. Meeting environmental standards and safety measures adds to the overall expense.",
    placeholder: "1x 10-gallon container of paint\n5x 5-gallon buckets of paint\n20x 1-gallon cans of paint",
  },
  "sharps": {
    heading: "Sharps Disposal for Medical Facilities",
    material: "Sharps (Medical Waste)",
    subHeading: "Safely dispose of medical sharps, whether it's a single container or multiple gallons, with our secure services.",
    costExplanation: "The cost of sharps disposal in medical facilities is associated with the need for specialized containers and disposal methods to prevent needlestick injuries and ensure compliance with medical waste regulations.",
    placeholder: "1x 2-gallon sharps disposal container\n5x 1-gallon sharps disposal containers\n20x 0.5-gallon sharps disposal containers",
  },
  "medical": {
    heading: "Safe Medical Waste Disposal Solutions",
    material: "Medical Waste",
    subHeading: "Safely dispose of biohazardous waste, sharps, and medical materials, no matter the quantity, with our trusted services.",
    costExplanation: "Medical waste disposal costs are higher due to the strict regulations governing the disposal of potentially infectious materials. Specialized containers, handling procedures, and disposal methods are essential to ensure safety and compliance.",
    placeholder: "1x 20-gallon biohazard waste container\n5x sharps containers for medical needles\n20x 1-gallon medical waste disposal bags",
  },
  "antifreeze": {
    heading: "Eco-Friendly Antifreeze Disposal and Recycling",
    material: "Antifreeze",
    subHeading: "Recycle used antifreeze efficiently, from single jugs to 15-gallon drums, and reduce environmental impact.",
    costExplanation: "Antifreeze disposal and recycling involve the treatment of potentially hazardous chemicals. Proper recycling methods, quality control, and environmental safeguards contribute to the overall cost.",
    placeholder: "1x 15-gallon drum of used antifreeze\n5x 5-gallon containers of antifreeze\n20x 1-gallon jugs of antifreeze",
  },
  "fuel": {
    heading: "Gasoline Disposal and Recycling",
    material: "Gasoline",
    subHeading: "Dispose of old gasoline and fuel containers, from small to large, while ensuring environmental responsibility.",
    costExplanation: "Gasoline disposal costs are influenced by the need for careful handling and recycling of flammable materials. Safety measures, compliance with regulations, and environmental protection efforts contribute to the cost.",
    placeholder: "1x 15-gallon drum of used gasoline\n5x 10-gallon containers of old gasoline\n20x 5-gallon containers of gasoline",
  },
  "chemicals": {
    heading: "Expert Chemical Waste Management",
    material: "Chemicals",
    subHeading: "Handle chemical waste professionally, from 5-gallon containers to 30-gallon barrels, with our experienced team.",
    costExplanation: "Chemical waste disposal costs reflect the complexity of handling and managing potentially hazardous substances. Strict safety protocols, specialized equipment, and environmental safeguards are essential components of chemical waste management.",
    placeholder: "1x 30-gallon barrel of chemical waste\n5x 10-gallon containers of chemicals\n20x 5-gallon containers of hazardous chemicals",
  },
  "ethanol": {
    heading: "Efficient Ethanol Waste Disposal",
    material: "Ethanol",
    subHeading: "Dispose of ethanol waste, from 1-gallon jugs to 15-gallon drums, while adhering to environmental standards.",
    costExplanation: "Ethanol waste disposal costs are influenced by the need for responsible management of flammable materials. Safety measures, regulatory compliance, and environmental protection efforts contribute to the cost.",
    placeholder: "1x 15-gallon drum of ethanol waste\n5x 5-gallon containers of ethanol\n20x 1-gallon jugs of ethanol",
  },
  "mercury": {
    heading: "Mercury Waste Management Solutions",
    material: "Mercury",
    subHeading: "Safely manage mercury waste, whether it's a single jar or a 5-gallon container, with our expertise.",
    costExplanation: "The cost of mercury waste management is associated with the need for specialized handling and disposal to prevent environmental contamination and health risks. Strict safety measures and compliance with regulations contribute to the cost.",
    placeholder: "1x 5-gallon container of mercury waste\n5x 1-gallon jars of mercury\n20x mercury thermometers",
  },
  "toxic": {
    heading: "Toxic Waste Disposal Services",
    material: "Hazardous Waste",
    subHeading: "From toxic barrels to smaller chemical containers, we handle all types of hazardous waste with expertise and care.",
    costExplanation: "The cost of hazardous waste disposal may seem high due to the complex nature of these materials. Specialized training, equipment, and disposal methods are necessary to protect both the environment and public health, leading to increased expenses.",
    placeholder: "1x 55-gallon drum of hazardous waste\n5x 30-gallon barrels of toxic materials\n20x 10-gallon containers of hazardous waste",
  },
  "hazardous": {
    heading: "Comprehensive Hazardous Waste Removal Services",
    material: "Hazardous Waste",
    subHeading: "From toxic barrels to smaller chemical containers, we handle all types of hazardous waste with expertise and care.",
    costExplanation: "The cost of hazardous waste disposal may seem high due to the complex nature of these materials. Specialized training, equipment, and disposal methods are necessary to protect both the environment and public health, leading to increased expenses.",
    placeholder: "1x 55-gallon drum of hazardous waste\n5x 30-gallon barrels of toxic materials\n20x 10-gallon containers of hazardous waste",
  },
  "electronics": {
    heading: "Secure and Responsible Electronic Waste Disposal",
    material: "Electronic Waste",
    subHeading: "Dispose of your electronic waste safely with our certified recycling services. From old computers to smartphones, ensure your e-waste is recycled responsibly and securely.",
    costExplanation: "The cost of electronic waste disposal reflects the need for specialized handling and recycling of potentially hazardous materials. Safety measures, compliance with regulations, and environmental protection efforts contribute to the cost.",
    placeholder: "1x pallet of old computers\n5x boxes of old smartphones\n20x miscellaneous electronic items",
  },
  "yard": {
    heading: "Eco-Friendly Yard Waste Removal",
    material: "Yard Waste",
    subHeading: "We provide convenient and eco-friendly solutions for your yard waste, from grass clippings to tree limbs, ensuring that your outdoor space remains pristine.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x pile of wood debris (from 14ft x 16ft shed)\n1x shed roof (14ft x 16ft)\n10 bags of leaves",
  },
  "dumpster": {
    heading: "Affordable Dumpster Rental Services",
    material: "General Waste",
    subHeading: "Whether you're clearing out your home, managing a renovation, or need a reliable waste disposal solution for your business, our dumpster rental services are tailored to meet your needs. We offer a variety of sizes and flexible rental periods at competitive prices.",
    costExplanation: "The cost of dumpster rental may vary depending on the size and rental period. Factors such as delivery, pickup, and disposal fees contribute to the overall cost.",
    placeholder: "1x 10-yard dumpster for 1 week\n1x 20-yard dumpster for construction debris",
  },
  "specialized": {
    heading: "Specialized Disposal Services for Unique Needs",
    material: "Special Waste",
    subHeading: "Handling difficult-to-dispose materials with ease. Whether it's rare chemicals, laboratory waste, or other specialized items, our expert team ensures safe and compliant disposal.",
    costExplanation: "The cost of specialized waste disposal may vary depending on the unique nature of the materials. Factors such as handling, storage, and disposal fees contribute to the overall cost.",
    placeholder: "1x 55-gallon drum of rare chemicals\n5x 10-gallon containers of laboratory waste\n20x specialized items",
  },
  "solar": {
    heading: "Solar Panel Recycling and Disposal Solutions",
    material: "Solar Panels",
    subHeading: "Eco-friendly disposal and recycling services for outdated or damaged solar panels. Ensure your solar panel waste is handled with care, supporting environmental sustainability.",
    costExplanation: "The cost of solar panel disposal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x pallet of solar panels\n5x broken solar panels\n20x solar panels",
  },
  "cardboard": {
    heading: "Efficient Cardboard Recycling Services",
    material: "Cardboard",
    subHeading: "Maximize recycling opportunities with our cardboard recycling services. From small boxes to large quantities, we help businesses and households reduce waste and support sustainability.",
    costExplanation: "The cost of cardboard recycling may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x pallet of cardboard boxes\n5x large moving boxes\n20x small cardboard boxes",
  },
  "furniture": {
    heading: "Cheap Furniture Disposal Solutions",
    material: "Furniture",
    subHeading: "Get rid of old furniture and bulky items with our convenient disposal services. From single items to large loads, we ensure safe and environmentally responsible disposal.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x 2-seater sofa\n5x chairs\n2 desks and 1 office chair",
  },
  "treadmill": {
    heading: "Treadmill Disposal and Recycling",
    material: "Treadmills",
    subHeading: "Dispose of old treadmills and gym equipment with our convenient recycling services. We ensure safe and environmentally responsible disposal.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x old treadmill\n1x foldable treadmill\n1x treadmill and elliptical machine",
  },
  "excercise": {
    heading: "Efficient Exercise Equipment Disposal",
    material: "Exercise Equipment",
    subHeading: "Dispose of old exercise equipment with our convenient recycling services. We ensure safe and environmentally responsible disposal.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x old treadmill\n1x foldable treadmill\n1x treadmill and elliptical machine",
  },
  "appliances": {
    heading: "Professional Appliance Disposal Services",
    material: "Appliances",
    subHeading: "Dispose of old appliances with our convenient disposal and recycling services. We ensure safe and environmentally responsible disposal.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x refrigerator\n1 washer and dryer\nFull set of kitchen appliances",
  },
  "washer": {
    heading: "Washer and Dryer Disposal Solutions",
    material: "Appliances",
    subHeading: "Dispose of old washers and dryers with our convenient recycling services. We ensure safe and environmentally responsible disposal.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x washer and dryer\nFull set of kitchen appliances",
  },
  "dryer": {
    heading: "Dryer Disposal and Recycling",
    material: "Appliances",
    subHeading: "Dispose of old dryers with our convenient recycling services. We ensure safe and environmentally responsible disposal.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x washer and dryer\nFull set of kitchen appliances",
  },
  "piano": {
    heading: "Piano Removal and Disposal Services",
    material: "Pianos",
    subHeading: "Dispose of pianos and other musical instruments with our convenient pickup, disposal and recycling services. We ensure safe and environmentally responsible disposal for uprights to grands.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x upright piano\n1x baby grand\n1x grand and bench",
  },
  "mattress": {
    heading: "Easy and Cheap Mattress Disposal",
    material: "Mattresses",
    subHeading: "Dispose of old mattresses and bed sets with our convenient recycling services. We ensure safe and environmentally responsible disposal.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x king bedframe and mattress\n5x full mattresses with headboards\n1 king and 2 queen mattresses",
  },
  "waste": {
    heading: "Professional Waste Disposal Services",
    material: "General Waste",
    subHeading: "Expert disposal services for all types of waste. Whether it's household junk, construction debris, or hazardous materials, we ensure safe and environmentally responsible disposal.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x bedframe and mattress\n5x dressers\n20x trash bags full of misc junk",
  },
  "junk": {
    heading: "Efficient and Eco-Friendly Junk Removal Services",
    material: "Junk",
    subHeading: "Get rid of unwanted items and clutter with our efficient junk removal services. We handle everything from small loads to large cleanouts.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x bedframe and mattress\n5x dressers\n20x trash bags full of misc junk",
  },
  "trash": {
    heading: "Reliable Trash Disposal Solutions",
    material: "Trash",
    subHeading: "From household trash to construction debris, we offer reliable and eco-friendly disposal solutions tailored to meet your needs.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x bedframe and mattress\n5x dressers\n20x trash bags full of misc junk",
  },
  "disposal": {
    heading: "Comprehensive Waste Disposal Services",
    material: "General Waste",
    subHeading: "From household junk to construction debris, we offer comprehensive waste disposal services tailored to meet your needs.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x bedframe and mattress\n5x dressers\n20x trash bags full of misc junk",
  },
  "nearme": {
    heading: "Your Local Waste Management Experts",
    material: "All Types",
    subHeading: "Find reliable waste management solutions near you. We offer a wide range of services, from recycling and composting to hazardous waste disposal, tailored to meet the unique needs of your community.",
    costExplanation: "The cost of junk removal may vary depending on the volume and type of items being removed. Factors such as labor, disposal fees, and recycling efforts contribute to the overall cost.",
    placeholder: "1x bedframe and mattress\n5x dressers\n20x trash bags full of misc junk",
  },
};

export const coreKeywords = Object.keys(keywordHeadingsAndPitches);


// If our initial estimate doesn't meet your expectations, our sales team will reach out to you to find a suitable solution.

export const contactInfoDescription = `
To kick off your service quote, we'll need some basic contact details. On the following page, you'll have the opportunity to outline your requirements in detail to receive an instant price estimate.
`;

export const contactInfoDescriptionV7 = `
We require this incase we need to reach you regarding your service. Your privacy is our priority. Rest assured, your information will remain confidential and will not be shared with third parties.
`;

export const contactInfoPrivacyDisclaimer = `
Your privacy is our priority. Rest assured, your information will remain confidential and will not be shared with third parties.
`;

export const priceInfoDescription = `If our initial estimate doesn't meet your expectations, our sales team will reach out to you to discuss your needs and work directly with you to find a suitable solution.`;
